@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
@forward "./listing/common";
@forward "./listing/related";
@forward "./details/common";
.waf-gallery-page {
  .item-type-icon {
    @extend %d-none;
  }
  .btn-more {
    @extend %pos-tl;
    @extend %w-100;
    @extend %h-100;
  }
  .article-gallery {
    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @extend %gap-3;
    }
    &-item {
      @extend %relative;
      &:nth-child(3n+1) {
        grid-column: span 2;
      }
    }
    // .img-2by3 {
    //   .image {
    //     object-fit: cover;
    //   }
    // }
  }
}
.modal-window {
  display: none;
  flex-direction: column;
  justify-content: center;
  @include background(var(--primary-900), "pattern/mobile/gallery-modal-bg.png", center / cover no-repeat);
  @extend %px-2;
  &.active {
    display: flex;
  }
  .download,
  .article-content,
  .article-description,
  .swiper-scrollbar {
    @extend %d-none;
  }
  .btn-close {
    z-index: var(--z-modal-close);
    @include position(var(--space-4), var(--space-2), null, null, fixed);
    &::after {
      @include icon(close-btn, 20);
      @extend %pure-white-900;
    }
  }
  .modal-head {
    order: 1;
  }
  .modal-body {
    width: 100%;
    order: 0;
  }
  .article-title {
    line-height: 2.2rem;
    @extend %font-16-pb;
    @extend %pure-white-900;
    @extend %mt-4;
  }
  .modal-footer {
    --swiper-button-right: 3rem;
    --swiper-button-bottom: 5rem;
    --swiper-button-width: 4.6rem;
    --swiper-button-height: 3.4rem;
    --swiper-icon-size: 1.4rem;
    --swiper-pagination-bottom: 0rem;
    @extend %w-100;
    @extend %relative;
    @extend %mt-4;
    @include flex-config(flex, row-reverse, space-between, center);
  }
  .social-share {
    padding: var(--space-0);
  }
  .icon-b-share {
    width: auto;
    height: auto;
    @extend %transparent-bg;
    @extend %pure-white-900;
    &::before {
      font-size: 1.8rem;
    }
  }
  .swiper-pagination {
    padding: var(--space-0);
    position: unset;
    width: auto;
    @extend %flex-c-c;
    @extend %gap-1;
    @extend %font-14-pm;
    @extend %pure-white-900;
    .swiper-pagination-current {
      @extend %font-18-pb;
      @extend %pure-white-900;
    }
  }
}
@include mq(col-md) {
  .modal-window {
    .article-title {
      font-size: 1.8rem;
    }
    .swiper {
      height: 70vh;
      .img-box{
        height: 70vh;
        padding: 0;
        background-color: transparent;
      }
      img{
        object-fit: contain;

      }
    }
    .swiper-pagination {
      .swiper-pagination-current {
        font-size: 2rem;
        line-height: 2rem;
      }
    }
  }
}
@include mq(col-xl) {
  .waf-detail {
    max-width: 83.88rem;
    margin-inline: auto;
  }
  .waf-gallery-page {
    .article-gallery {
      &-list {
        grid-template-columns: repeat(4, 1fr);
      }
      &-item {
        grid-row: span 2;
        &:nth-child(3n+1) {
          grid-column: unset;
        }
        &:nth-child(8n+2),
        &:nth-child(8n+4),
        &:nth-child(8n+5),
        &:nth-child(8n+6) {
          grid-row: span 3;
        }
      }
    }
  }
  .modal-window {
    padding: var(--space-0);
    @include background(var(--primary-900), "pattern/gallery-modal-bg.png", center / cover no-repeat);
    .modal-head,
    .modal-footer {
      width: 58%;
      margin-inline: auto;
    }
    .modal-body {
      max-width: 100%;
    }
    .modal-footer {
      --swiper-button-right: 15%;
      --swiper-button-bottom: 4rem;
      --swiper-button-width: 4rem;
      --swiper-button-height: 4rem;
      --swiper-icon-size: 1.8rem;
      margin-top: var(--space-6);
    }
    .article-list {
      gap: var(--space-0);
    }
    .btn-close {
      top: 4rem;
      right: 17%;
      &::after {
        font-size: 2rem;
      }
    }
    .swiper-slide-next,
    .swiper-slide-prev {
      scale: 0.8;
      opacity: 0.6;
      top: 6%;
    }
    .icon-b-share {
      background-color: var(--primary-700);
      padding: var(--space-1);
      width: 3rem;
      height: 3rem;
    }
  }
}